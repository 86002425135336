<template>
  <div class="selectCoupon" >
    <div class="header"><img src="../assets/chat_btn_return.png" alt=""  @click="returnFun">选择优惠券/折扣</div>
    <div class="main" >
      <div class="item" :class="item.CouponsName=='新用户图文咨询9.9元'||item.CouponsName=='新用户1个月会员38元'?'new':''" v-for="(item,index) in yhList" @click="chose(index)">
        <div class="tag" v-if="item.CouponsName.indexOf('会员专享')>=0||item.CouponsName=='新用户图文咨询9.9元'">限系统指派律师</div>
        <div class="top">
          <div class="price" v-if="item.Price==0"><span>免费</span></div>
          <div class="price" v-else>¥ <span>{{item.Price}}</span></div>
          <div class="info">
            <div>{{item.CouponsName}}</div>
            <div v-if="item.CouponsName=='新用户图文咨询9.9元'||item.CouponsName=='新用户1个月会员38元'">首单特惠</div>
            <div v-else>{{item.EndDate}}到期</div>
          </div>
          <img :src="thisyh.YhGuid==item.YhGuid?require('../assets/icon_evaluate_selected.png'):require('../assets/icon_evaluate_normal.png')" alt="">
        </div>
        <div class="bottom">
          <img src="../assets/icon_prompt.png" alt="">
          <span>{{item.CouponType}}</span>
        </div>
      </div>
      <div class="item"  @click="chose(-1)">
        <div class="top" >
          <div class="info"><div>暂不使用优惠</div></div>
          <img :src="thisyh.YhGuid==1?require('../assets/icon_evaluate_selected.png'):require('../assets/icon_evaluate_normal.png')" alt="">
        </div>
      </div>
    </div>
    <div class="foot">
      <div class="btn" @click="confirm">确认使用</div>
    </div>
  </div>
</template>
<script>
  
  export default {
    name: 'selectCoupon',
    data() {
      return {
        yh:{},
        yhList:[],
        thisyh:{}
      }
    },
    mounted() {
      this.yh=JSON.parse(localStorage.getItem('yh'))
      this.yhList=JSON.parse(localStorage.getItem('yhlist'))
      for(var i=0;i<this.yhList.length;i++){
        if(this.yhList[i].YhGuid==this.yh.YhGuid){
          this.thisyh=this.yhList[i]
        }
      }
    },
    methods: {
       returnFun(){
        this.$router.back()
      },
      chose(index){
        if(index==-1){
          this.thisyh={
            CouponType: "",
            CouponsName: "暂不使用优惠",
            CouponsType: "",
            EndDate: "",
            Price: 0,
            UseState: "",
            YhGuid: "1",
          }
        }else{
          this.thisyh=this.yhList[index]
        }
      },
      confirm(){
        localStorage.setItem('choseyh',JSON.stringify(this.thisyh))
        this.$router.back()
      }
    },
    components: {
     
    }
  }

</script>

<style scoped lang="scss">
.selectCoupon{
  width: 100%;height: 100%;
  position: absolute;
  top: 0;
  left: 0;background: #F0F0F0;
  .header{
    background: #fff;
    text-align: center;height: 80px;line-height: 80px;font-size: 30px;font-weight: bold;
color: #000000;position: relative;
    img{
      width: 17px;
      position: absolute;
      top: 25px;
      left: 30px;
    }
  }
  .main{
    height: calc(100% - 200px);
    overflow: auto;
    box-sizing: border-box;
    padding: 30px;
    .item{
      padding: 0 30px;
      background: #FFFFFF;
      box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.04);
      border-radius: 20px;position: relative;
      .tag{
        width: 190px;
        height: 30px;
        background: #F24537;
        border-radius: 20px 0px 20px 0px;
        font-size: 22px;color: #FFFFFF;text-align: center;line-height: 30px;
        position: absolute;
        top: 0;left: 0;
      }
      .top{
        padding: 40px 0 30px;position: relative;
        >div{
          display: inline-block;vertical-align: middle;
        }
        .price{
          font-size: 24px;font-weight: bold;color: #E93939;width: 132px;
          span{
            font-size: 40px;
          }
        }
        .info{
          >div:nth-child(1){
            font-size: 30px;font-weight: bold;color: #333333;margin-bottom: 10px;
          }
          >div:nth-child(2){
            font-size: 26px;color: #666666;
          }
        }
        >img{
          width: 40px;
          position: absolute;
          right: 0px;
          top: 50%;
          margin-top: -20px;
        }
      }
      .bottom{
        border-top: 1px solid #F5F5F8;
        padding: 14px 0;
        img{
          width: 30px;vertical-align: middle;margin-right: 20px;
        }
        span{
          font-size: 24px;font-weight: bold;color: #FF8400;vertical-align: middle;
        }
      }
    }
    .item:last-child .top{
      padding: 40px 0;
      .info>div{
        margin: 0;
      }
    }
    .new{
      background: linear-gradient(-13deg, #FFF2E3 0%, #FFFAEE 100%);
    }
    .item:not(:last-child){
      margin-bottom: 30px;
    }
  }
  .foot{
    height: 120px;
    background: #FFFFFF;
    box-sizing: border-box;
    padding: 15px 30px;
    .btn{
      width: 690px;
      height: 90px;
      background: #21D0C8;
      border-radius: 45px;font-size: 32px;color: #FFFFFF;
      text-align: center;line-height: 90px;
    }
    
  }
 
}
.white{
  background: #fff;
}

</style>
<style lang="scss">
.comment{
  .van-rate__icon{
        font-size: 70px!important;
      }
  .van-rate__item:not(:last-child){
    padding-right: 24px;
  }
  .van-rate{
    margin: 0 auto;text-align: center;
    display: block;margin-bottom: 60px;
  }
  .van-rate__item{
    display: inline-block;
  }
  .van-cell{
    padding: 0;
  }
  .van-field__control{
    padding: 20px;
        background: #fff;box-sizing: border-box;
        width: 100%;height: 290px!important;border: 0;border-radius: 10px;font-size: 28px;color: #666666;
  }
  .active .van-field__control{
    background: #F0F0F0;
  }
}


</style>
